<template>
  <div>
    <template v-if="region !== null">
      <b-table
          :fields="tableFields"
          :items="budgetForm"
          :tbody-tr-class="rowClass"
          :filter="filter.search"
          :filter-included-fields="filter.on"
          responsive="true"
          bordered
          head-variant="light"
          sticky-header="true"
          :tbody-transition-props="transProps"
          no-border-collapse>
        <template #thead-top="data">
          <b-tr>
            <b-th v-bind:colspan="totalHead"></b-th>
            <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year)) + ' год' }}</b-th>
            <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year)) + ' год' }}</b-th>
            <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 1) + ' год' }}</b-th>
            <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 1) + ' год' }}</b-th>
            <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 2) + ' год' }}</b-th>
            <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 2) + ' год' }}</b-th>
          </b-tr>
        </template>
        <template #head(action)="scope">
        </template>
        <template #head(gr)="scope">
          <div>ФГ</div>
        </template>
        <template #head(pgr)="scope">
          <div>ФПГ</div>
        </template>
        <template #head(abp)="scope">
          <div>АБП</div>
        </template>
        <template #head(prg)="scope">
          <div>БП</div>
        </template>
        <template #head(ppr)="scope">
          <div>БПП</div>
        </template>
        <template #head(spf)="scope">
          <div>СП</div>
        </template>
        <template #head(bip_code)="scope">
          <div>Проект</div>
        </template>
        <template #head(name_ru)="scope">
          <div>Наименование</div>
        </template>
        <template #head(receipt_prev)="scope">
          <div>{{ 'Исполнение за ' + (parseInt(year) - 2) + ' год' }}</div>
        </template>
        <template #head(plan)="scope">
          <div v-if="dataType === 1">{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) + ' без учета ЦТ из РБ' }}</div>
          <div v-else>{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) }}</div>
        </template>
        <template #head(grow1)="scope">
          <div>{{ '% роста к ' + (parseInt(year) - 1) + ' году' }}</div>
        </template>
        <template #head(deviation1)="scope">
          <div>{{ 'отклонение к ' + (parseInt(year) - 1) + ' году' }}</div>
        </template>
        <template #head(grow2)="scope">
          <div>{{ '% роста к ' + (parseInt(year)) + ' году' }}</div>
        </template>
        <template #head(deviation2)="scope">
          <div>{{ 'отклонение к ' + (parseInt(year)) + ' году' }}</div>
        </template>
        <template #head(grow3)="scope">
          <div>{{ '% роста к ' + (parseInt(year) + 1) + ' году' }}</div>
        </template>
        <template #head(deviation3)="scope">
          <div>{{ 'отклонение к ' + (parseInt(year) + 1) + ' году' }}</div>
        </template>
        <template #top-row="data" v-if="totals !== null">
          <td class="text-center" v-bind:colspan="totalSpan">{{ totals.name_ru }}</td>
          <td class="text-right">{{ $n(toNum(totals.receipt_prev)) }}</td>
          <td class="text-right">{{ $n(toNum(totals.plan)) }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp1)) }}</td>
          <td class="text-right">{{ totals.plan > 0 ? $n(toNum((totals.rp1 / totals.plan) * 100)) : 0 }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp1) - toNum(totals.plan)) }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp2)) }}</td>
          <td class="text-right">{{ totals.rp2 > 0 ? $n(toNum((totals.rp2 / totals.rp1) * 100)) : 0  }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp2) - toNum(totals.rp1)) }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp3)) }}</td>
          <td class="text-right">{{ totals.rp2 > 0 ? $n(toNum((totals.rp3 / totals.rp2) * 100)) : 0 }}</td>
          <td class="text-right">{{ $n(toNum(totals.rp3) - toNum(totals.rp2)) }}</td>
        </template>
        <template #cell(action)="data">
          <b-button v-if="data.item.type === 1" @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(gr)="data">
          <div v-if="data.item.type === 1" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 2) && data.item.hasChild"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(pgr)="data">
          <div v-if="data.item.type === 2" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 3) && data.item.hasChild"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(abp)="data">
          <div v-if="data.item.type === 3" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 4) && data.item.hasChild"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(prg)="data">
          <div v-if="data.item.type === 4" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 5) && data.item.hasChild"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(ppr)="data">
          <div v-if="data.item.type === 5" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 6) && data.item.hasChild && develop > 0"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(spf)="data">
          <div v-if="data.item.type === 6" class="text-right">{{ data.value }}</div>
          <b-button v-if="(data.item.type === 7) && data.item.hasChild"
                    @click="openChilds(data.item)">
            <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
            <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
          </b-button>
        </template>
        <template #cell(bip_code)="data">
          <div v-if="data.item.type === 7" class="text-left">{{ data.value }}</div>
        </template>
        <template #cell(name_ru)="data">
          <div>{{ data.value }}</div>
        </template>
        <template #cell()="data">
          <div class="text-right">
            {{ $n(toNum(data.value)) }}
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'CostFunc',
    props: {
        year: Number,
        region: String,
        develop: Number,
        version: Number,
        totals: Object,
        dictTree: null,
        dataType: Number,
        selectedAbp: null,
        selectedPrg: null,
        selectedPpr: null
    },
    data() {
        return {
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'gr',
                    label: 'ФГ',
                    class: 'toggle-show'
                },
                {
                    key: 'pgr',
                    label: 'ФПГ',
                    class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП',
                    class: 'toggle-show'
                },
                {
                    key: 'prg',
                    label: 'БП',
                    class: 'toggle-show'
                },
                {
                    key: 'ppr',
                    label: 'БПП',
                    class: 'toggle-show'
                },

                {
                    key: 'spf',
                    label: 'СП',
                    class: 'toggle-show'
                },
                {
                    key: 'bip_code',
                    label: 'Проект',
                    class: 'toggle-show'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'plan',
                    label: ''
                },
                {
                    key: 'rp1',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow1',
                    label: ''
                },
                {
                    key: 'deviation1',
                    label: ''
                },
                {
                    key: 'rp2',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow2',
                    label: ''
                },
                {
                    key: 'deviation2',
                    label: ''
                },
                {
                    key: 'rp3',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow3',
                    label: ''
                },
                {
                    key: 'deviation3',
                    label: ''
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            selectedTree: [],
            budgetForm: [],
            budgetFormTemp: [],
            budgetDataMap: new Map(),
            filter: {
                gr: null,
                pgr: null,
                abp: null,
                prg: null,
                ppr: null,
                spf: null,
                bip_code: null,
                name_ru: null,
                search: null,
                on: ['filter', 'gr', 'pgr', 'abp', 'prg', 'ppr', 'spf', 'bip_code', 'name_ru']
            },
            open: true,
            totalSpan: 9,
            totalHead: 11
        };
    },

    created() {
        this.$watch('region', this.changeParams);
        this.$watch('develop', this.developVersion);
        this.$watch('version', this.developVersion);
    },

    async mounted() {
        await this.prepareForm();
    },

    beforeUpdate() {
        for (const row of this.budgetForm) {
            if (this.develop === 0) {
                if ([1, 2, 3, 4, 5].includes(row.type) && row.hasChild) {
                    row._rowVariant = 'info';
                }
            } else {
                if ([1, 2, 3, 4, 5, 6].includes(row.type) && row.hasChild) {
                    row._rowVariant = 'info';
                }
            }
            if ([4].includes(row.type) && !row.hasChild) {
                this.$set(row, 'editable', true);
            }
        }
    },

    methods: {
        versionChange(item) {
            if (this.version !== 0) {
                if (this.develop === 0) {
                    this.tableFields[6].thClass = '';
                    this.tableFields[6].tdClass = '';
                    this.tableFields[7].thClass = 'd-none';
                    this.tableFields[7].tdClass = 'd-none';
                    if ([7].includes(item.type)) {
                        item.visible = false;
                    }
                    if ([6].includes(item.type)) {
                        item.visible = true;
                    }
                    this.totalSpan = 8;
                    this.totalHead = 10;
                } else {
                    if ([6, 7].includes(item.type)) {
                        item.visible = true;
                    }
                    this.tableFields[6].thClass = '';
                    this.tableFields[6].tdClass = '';
                    this.tableFields[7].thClass = '';
                    this.tableFields[7].tdClass = '';
                    this.totalSpan = 9;
                    this.totalHead = 11;
                }
            } else {
                if (this.develop === 0) {
                    this.tableFields[6].thClass = '';
                    this.tableFields[6].tdClass = '';
                    this.tableFields[7].thClass = 'd-none';
                    this.tableFields[7].tdClass = 'd-none';
                    if ([7].includes(item.type)) {
                        item.visible = false;
                    }
                    if ([6].includes(item.type)) {
                        item.visible = true;
                    }
                    this.totalSpan = 8;
                    this.totalHead = 10;
                } else {
                    if ([6, 7].includes(item.type)) {
                        item.visible = true;
                    }
                    this.tableFields[6].thClass = '';
                    this.tableFields[6].tdClass = '';
                    this.tableFields[7].thClass = '';
                    this.tableFields[7].tdClass = '';
                    this.totalSpan = 9;
                    this.totalHead = 11;
                }
            }
        },
        async changeParams() {
            this.open = true;
            await this.prepareForm();
        },

        createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);

            that.$set(item, 'rp1', 0);
            that.$set(item, 'rp2', 0);
            that.$set(item, 'rp3', 0);
            const par = that.budgetDataMap.get(item.parent_id);
            if (par) {
                if (par.hasOwnProperty('newchild')) {
                    that.$set(par.newchild, par.newchild.length, item);
                } else {
                    that.$set(par, 'newchild', []);
                    that.$set(par.newchild, par.newchild.length, item);
                }
            }
            Object.defineProperty(item, 'parent', {
                get: function () {
                    const par = that.budgetDataMap.get(item.parent_id);
                    if (par) {
                        return par;
                    }
                    return null;
                }
            });
            this.changeDevelop(item);
            Object.defineProperty(item, 'grow1', {
                get: function () {
                    const rate = (that.toNum(item.plan) === 0 ? 0
                        : (that.toNum(item.rp1) / that.toNum(item.plan)) * 100);
                    return that.toNum(rate, 1);
                }
            });
            Object.defineProperty(item, 'deviation1', {
                get: function () {
                    return (item.rp1 - item.plan);
                }
            });
            Object.defineProperty(item, 'grow2', {
                get: function () {
                    // return parseFloat(((item.rp2 / item.rp1) * 100).toFixed(1));
                    const rate = (that.toNum(item.rp1) === 0 ? 0
                        : (that.toNum(item.rp2) / that.toNum(item.rp1)) * 100);
                    return that.toNum(rate, 1);
                }
            });
            Object.defineProperty(item, 'deviation2', {
                get: function () {
                    return (item.rp2 - item.rp1);
                }
            });
            Object.defineProperty(item, 'grow3', {
                get: function () {
                    // return parseFloat(((item.rp3 / item.rp2) * 100).toFixed(1));
                    const rate = (that.toNum(item.rp2) === 0 ? 0
                        : (that.toNum(item.rp3) / that.toNum(item.rp2)) * 100);
                    return that.toNum(rate, 1);
                }
            });
            Object.defineProperty(item, 'deviation3', {
                get: function () {
                    return (item.rp3 - item.rp2);
                }
            });


            that.$set(item, 'index', that.budgetFormTemp.length);
            that.$set(that.budgetFormTemp, that.budgetFormTemp.length, item);
            that.budgetDataMap.set(item.id, item);
            if (item.hasOwnProperty('child') && (item.child.length > 0)) {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        changeDevelop(item) {
            this.versionChange(item);
            if (this.develop === 0) {
                item.receipt_prev = item.receipt_prev_0;
                item.plan = item.plan_0;
                item.request1 = item.request1_0;
                item.request2 = item.request2_0;
                item.request3 = item.request3_0;
                item.plan1 = item.plan1_0;
                item.plan2 = item.plan2_0;
                item.plan3 = item.plan3_0;
                if ([7].includes(item.type)) {
                    item.visible = false;
                }
                this.tableFields[7].thClass = 'd-none';
                this.tableFields[7].tdClass = 'd-none';

            }
            if (this.develop === 1) {
                item.receipt_prev = item.receipt_prev_1;
                item.plan = item.plan_1;
                item.request1 = item.request1_1;
                item.request2 = item.request2_1;
                item.request3 = item.request3_1;
                item.plan1 = item.plan1_1;
                item.plan2 = item.plan2_1;
                item.plan3 = item.plan3_1;
                if ([7].includes(item.type)) {
                    item.visible = true;
                }
                this.tableFields[7].thClass = '';
                this.tableFields[7].tdClass = '';
            }
            if (this.develop === 2) {
                item.receipt_prev = item.receipt_prev_;
                item.plan = item.plan_;
                item.request1 = item.request1_;
                item.request2 = item.request2_;
                item.request3 = item.request3_;
                item.plan1 = item.plan1_;
                item.plan2 = item.plan2_;
                item.plan3 = item.plan3_;
                if ([7].includes(item.type)) {
                    item.visible = true;
                }
                this.tableFields[7].thClass = '';
                this.tableFields[7].tdClass = '';

            }
            // this.versionChange(item);
            this.changeVersion(item);
        },

        changeVersion(item) {
            if (this.version === 0) {
                item.rp1 = item.request1;
                item.rp2 = item.request2;
                item.rp3 = item.request3;
            } else {
                item.rp1 = item.plan1;
                item.rp2 = item.plan2;
                item.rp3 = item.plan3;
            }
        },

        visDevelop(item) {
            if ([4, 5, 6, 7].includes(item.type)) {
                item.visible = false;
                if (this.develop === 2) {
                    item.visible = true;
                } else {
                    if (this.develop === item.develop_type) {
                        item.visible = true;
                    }
                    if ((item.develop_type === 0) && ([7].includes(item.type))) {
                        item.visible = false;
                    }
                }
            }
        },

        async deleteNulls() {
            for (const row of this.budgetForm) {
                if (parseFloat(row.receipt_prev)
            + parseFloat(row.plan)
            + parseFloat(row.rp1)
            + parseFloat(row.rp2)
            + parseFloat(row.rp3) === 0) {
                    row.visible = false;
                }
            }
        },
        compare_spf(a, b) {
            if (a.spf < b.spf) {
                return -1;
            }
            if (a.spf > b.spf) {
                return 1;
            }
            return 0;
        },
        compare_ppr(a, b) {
            if (a.ppr < b.ppr) {
                return -1;
            }
            if (a.ppr > b.ppr) {
                return 1;
            }
            return 0;
        },
        compare_prg(a, b) {
            if (a.prg < b.prg) {
                return -1;
            }
            if (a.prg > b.prg) {
                return 1;
            }
            if (a.ppr === undefined) {
                return -1;
            }
            if (b.ppr === undefined) {
                return 1;
            }
            return 0;
        },
        compare_abp(a, b) {
            if (a.abp < b.abp) {
                return -1;
            }
            if (a.abp > b.abp) {
                return 1;
            }
            if (a.prg === undefined) {
                return -1;
            }
            if (b.prg === undefined) {
                return 1;
            }
            return 0;
        },
        compare_null(a, b) {
            if ((a.abp === undefined) || (b.abp !== undefined)) {
                return -1;
            }
            return 1;
        },


        developVersion() {
            this.changeDevelop(this.totals);
            for (const row of this.budgetFormTemp) {
                this.changeVersion(row);
            }
            for (const row of this.budgetForm) {
                this.changeDevelop(row);
            }
            this.open = true;
            this.openAll();
        },

        openAll() {
            this.open = !this.open;
            for (const row of this.budgetForm) {
                if ([1, 2, 3, 4, 5].includes(row.type)) {
                    row.visible = this.open;

                } else {
                    row.open = false;
                    row.visible = false;
                }
                if ([1].includes(row.type)) {
                    row.visible = true;
                }
                if (this.open) {
                    this.visDevelop(row);
                }
            }
            this.deleteNulls();
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);
            if (parent.newchild) {
                let dat = parent.newchild;
                dat = dat.sort(this.compare_spf);
                dat = dat.sort(this.compare_ppr);
                dat = dat.sort(this.compare_prg);
                dat = dat.sort(this.compare_abp);
                dat = dat.sort(this.compare_null);
                for (const row of dat) {
                    if ((parent.id === row.parent_id)) {
                        this.changeDevelop(row);
                        const ind = this.budgetForm.indexOf(parent);
                        if (this.budgetForm.indexOf(row) < 0) {
                            this.budgetForm.splice(ind + 1, 0, row);
                        }
                        if ([1, 2, 3, 4].includes(row.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ([5, 6].includes(row.type)) {
                            row.visible = parent.open;
                            row.open = false;
                            this.openChilds(row, row.open);
                        }
                        if ([5, 6].includes(parent.type)){
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ((this.budgetForm.indexOf(row) > 0) && (!row.visible)) {
                            this.budgetForm.splice(this.budgetForm.indexOf(row), 1);
                        }
                        this.deleteNulls();
                    }
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        async prepareForm() {
            this.budgetForm = [];
            this.budgetFormTemp = [];
            this.budgetDataMap = new Map();
            for (const item of this.dictTree) {
                await this.createTable(item, 0);
            }
            for (const item of this.budgetFormTemp) {
                if (item.type == 1) {
                    item.open = false;
                    this.budgetForm.push(item)
                }
            }

            this.changeDevelop(this.totals);
            this.deleteNulls();
        }, // подготовка отображения данных

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'd-md-none';
            }
        }, // задает класс 'is-hidden' заданной строке

        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },

        toStr(value) {
            if (typeof (value) === 'string') {
                return value;
            }
            if (typeof (value) === 'number') {
                return value.toString();
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 'true' : 'false');
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return '';
            }
        }
    }
};
</script>
