<template>
    <div>
        <template>
           <b-table
                id="my-table"
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
                select-mode="single"
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="8"></b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                    </b-tr>
                </template>
<!--                <template #head(action)="scope" v-if="selectedSpf === null">-->
<!--                    <b-button @click="openAll()">-->
<!--                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>-->
<!--                        <i class="icon icon-chevron-circle" v-if="!open"></i>-->
<!--                    </b-button>-->
<!--                </template>-->
                <template #head(spf)="scope">
                    <div>код специфики</div>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                </template>
                <template #head(receipt_prev)="scope" >
                    <div>{{ 'Исполнение за ' + (parseInt(year) - 2) + ' год' }}</div>
                </template>
                <template #head(plan)="scope">
                    <div  v-if="dataType === 1">{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) + ' без учета ЦТ из РБ' }}</div>
                    <div  v-else>{{ 'Уточненный план на 1 апреля ' + (parseInt(year) - 1) }}</div>
                </template>
                <template #head(grow1)="scope">
                    <div>{{ '% роста к ' + (parseInt(year) - 1) + ' году' }}</div>
                </template>
                <template #head(deviation1)="scope">
                    <div>{{ 'отклонение к ' + (parseInt(year) - 1) + ' году' }}</div>
                </template>
                <template #head(grow2)="scope">
                    <div>{{ '% роста к ' + (parseInt(year) ) + ' году' }}</div>
                </template>
                <template #head(deviation2)="scope">
                    <div>{{ 'отклонение к ' + (parseInt(year) ) + ' году' }}</div>
                </template>
                <template #head(grow3)="scope">
                    <div>{{ '% роста к ' + (parseInt(year) + 1) + ' году' }}</div>
                </template>
                <template #head(deviation3)="scope">
                    <div>{{ 'отклонение к ' + (parseInt(year) + 1) + ' году' }}</div>
                </template>
                <template #top-row="data" v-if="totals !== null && selectedSpf === null">
                    <td class="text-center" colspan="6">{{ totals.name_ru }}</td>
                    <td class="text-right">{{ $n(toNum(totals.receipt_prev)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.plan)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.req1)) }}</td>
                    <td class="text-right">{{ totals.plan > 0 ? $n(toNum((totals.req1 / totals.plan) * 100)) : 0 }} </td>
                    <td class="text-right">{{ $n(toNum(totals.req1 - totals.plan)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.req2)) }}</td>
                    <td class="text-right">{{ totals.req1 > 0 ? $n(toNum((totals.req2 / totals.req1) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.req2 - totals.req1)) }}</td>
                    <td class="text-right">{{ $n(toNum(totals.req3)) }}</td>
                    <td class="text-right">{{ totals.req2 > 0 ? $n(toNum((totals.req3 / totals.req2) * 100)) : 0 }}</td>
                    <td class="text-right">{{ $n(toNum(totals.req3 - totals.req2)) }}</td>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type == 1" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 1" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 2) && data.item.hasChild"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type == 2" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type == 3) && data.item.hasChild"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type == 3" class="text-right">{{ padLeadingZeros(data.value, 3) }}</div>
                    <b-button v-if="(data.item.type == 4) && data.item.hasChild"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type == 4" class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ $n(toNum(data.value)) }}
                    </div>
                </template>
            </b-table>
        </template>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'CostEcon',
    props: {
        year: Number,
        region: String,
        version: Number,
        totals: Object,
        dictTree: null,
        dataType: Number,
        selectedSpf: Number
    },
    data() {
        return {
            selected: {},
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                     class: 'toggle-show'
                },
                {
                    key: 'spf',
                    label: 'код специфики',
                     class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП',
                     class: 'toggle-show'
                },
                {
                    key: 'prg',
                    label: 'БП',
                     class: 'toggle-show'
                },
                {
                    key: 'ppr',
                    label: 'БПП',
                     class: 'toggle-show'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'plan',
                    label: ''
                },
                {
                    key: 'req1',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow1',
                    label: ''
                },
                {
                    key: 'deviation1',
                    label: ''
                },
                {
                    key: 'req2',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow2',
                    label: ''
                },
                {
                    key: 'deviation2',
                    label: ''
                },
                {
                    key: 'req3',
                    label: 'сумма',
                    variant: 'info'
                },
                {
                    key: 'grow3',
                    label: ''
                },
                {
                    key: 'deviation3',
                    label: ''
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            budgetForm: [],
            budgetFormTemp: [],
            open: true,
            budgetDataMap: new Map(),
            perPage: 100,
            currentPage: 1
        };
    },

    created() {
        this.$watch('region', this.prepareForm);
        this.$watch('selectedSpf', this.prepareForm);
        this.$watch('version', this.developVersion);
    },

    async mounted() {
        await this.prepareForm();
        await this.openAll();
    },
    beforeUpdate() {
        for (const row of this.budgetForm) {
            if ([1, 2, 3].includes(row.type) && row.hasChild) {
                row._rowVariant = 'info';
            } else {
                row._rowVariant = 'light ';
            }
        }
    },
    methods: {
        async prepareForm() {
            this.budgetForm = [];
            this.budgetFormTemp = [];
            this.budgetDataMap = new Map();
            for (const item of this.dictTree) {
                if (this.selectedSpf === null) {
                    await this.createTable(item, 0);
                } else {
                    if (item.spf === this.selectedSpf) {
                        await this.createTable(item, 0);
                    }
                }
            }
            for (const row of this.budgetFormTemp) {
                this.changeVersion(row);
            }
            for (const item of this.budgetFormTemp) {
                if (item.type == 1) {
                    item.open = false;
                    this.budgetForm.push(item);
                }
            }
            this.changeVersion(this.totals);
            // this.budgetForm = this.budgetFormTemp
        }, // подготовка отображения данных

        async createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;
            const item = Object.assign({}, elem);
            // const item = JSON.parse(JSON.stringify(elem));
            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);
            const par = that.budgetDataMap.get(item.parent_id);
            if (par) {
                if (par.hasOwnProperty('newchild')) {
                    that.$set(par.newchild, par.newchild.length, item);
                } else {
                    that.$set(par, 'newchild', []);
                    that.$set(par.newchild, par.newchild.length, item);
                }
            }
            this.changeVersion(item);
            Object.defineProperty(item, 'grow1', {
                get: function () {
                    return (item.plan === null || item.plan === 0 ? 0 : that.toNum((item.req1 / item.plan) * 100));
                }
            });
            Object.defineProperty(item, 'deviation1', {
                get: function () {
                    return (item.req1 - item.plan);
                }
            });
            Object.defineProperty(item, 'grow2', {
                get: function () {
                    return (item.req1 === null || item.req1 === 0 ? 0 : that.toNum((item.req2 / item.req1) * 100));
                }
            });
            Object.defineProperty(item, 'deviation2', {
                get: function () {
                    return (item.req2 - item.req1);
                }
            });
            Object.defineProperty(item, 'grow3', {
                get: function () {
                    return (item.req2 === null || item.req2 === 0 ? 0 : that.toNum((item.req3 / item.req2) * 100));
                }
            });
            Object.defineProperty(item, 'deviation3', {
                get: function () {
                    return (item.req3 - item.req2);
                }
            });

            that.$set(item, 'index', that.budgetFormTemp.length);
            that.$set(that.budgetFormTemp, that.budgetFormTemp.length, item);
            that.budgetDataMap.set(item.id, item);
            if (item.hasOwnProperty('child') && (item.child.length > 0)) {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }
            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        },
        changeVersion(item) {
            if (this.version === 0) {
                item.req1 = item.request1;
                item.req2 = item.request2;
                item.req3 = item.request3;
                if ([1, 2, 3].includes(item.type) && item.hasChild) {
                    item._rowVariant = 'info';
                } else {
                    item._rowVariant = 'light ';
                }
            } else {
                item.req1 = item.urequest1;
                item.req2 = item.urequest2;
                item.req3 = item.urequest3;
                if ([1, 2, 3].includes(item.type) && item.hasChild) {
                    item._rowVariant = 'info';
                } else {
                    item._rowVariant = 'light ';
                }
            }
            this.open = true;
            this.openAll();
        },
        developVersion() {
            this.changeVersion(this.totals);
            for (const row of this.budgetFormTemp) {
                this.changeVersion(row);
            }
            for (const row of this.budgetForm) {
                this.changeVersion(row);
            }
            this.open = true;
            this.openAll();
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        padLeadingZeros(num, size) {    // -------добавление нулей перед значением в зависимости от размера значения
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        onRowSelected(arr) {
            if (arr.length > 0) {
                this.selected = arr[0];
            }
        }, // выделяет строку
        async openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([1, 2, 3].includes(row.type)) {
                    row.open = this.open;
                }
                if ([1].includes(row.type)) {
                    row.visible = true;
                }
                if (this.version === 0){
                    if (parseFloat(row.receipt_prev)
                        + parseFloat(row.plan)
                        + parseFloat(row.request1)
                        + parseFloat(row.request2)
                        + parseFloat(row.request3) === 0) {
                        row.visible = false;
                    }
                } else if (this.version === 1){
                    if (parseFloat(row.receipt_prev)
                        + parseFloat(row.plan)
                        + parseFloat(row.urequest1)
                        + parseFloat(row.urequest2)
                        + parseFloat(row.urequest3) === 0) {
                        row.visible = false;
                    }
                }
            }
            this.open = true;
        }, // открывает.закрывает все ветки
        openChilds(parent, bool) {
            parent.open = (bool == undefined ? !parent.open : bool);
            if (parent.newchild) {
                for (const row of parent.newchild.reverse()) {
                    if ((parent.id === row.parent_id) && (parent.spf === row.spf)) {
                        const ind = this.budgetForm.indexOf(parent);
                        if (this.budgetForm.indexOf(row) < 0) {
                            this.budgetForm.splice(ind + 1, 0, row);
                        }
                        if ([1, 2, 3, 4].includes(row.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = true;
                        }
                        if ((this.budgetForm.indexOf(row) > 0) && (!row.visible)) {
                            this.budgetForm.splice(this.budgetForm.indexOf(row), 1);
                        }
                    }
                }
            }
        }, // открывает/закрывает ветку до конечного элемента


        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },

        toStr(value) {
            if (typeof (value) === 'string') {
                return value;
            }
            if (typeof (value) === 'number') {
                return value.toString();
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 'true' : 'false');
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return '';
            }
        }
    }
};
</script>